import React from "react";
import { Container, Breadcrumb, Row, Col, Button} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';
import ReactStars from "react-rating-stars-component";
import DataTable from '../../components/data/banque/avisbanque'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'

function avis() {

	const banque = 'Hello Bank'
	const go = `/go/?go=${banque}`
	
	const star1 = {size: 22,value: 4.2,color: "grey",activeColor: "#ffc107",edit: false,};
	const star2 = {size: 22,value: 4.1,color: "grey",activeColor: "#ffc107",edit: false,};
	const star3 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star4 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star5 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star6 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,}; 
	

    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Avis HelloBank : à lire absolument avant d'ouvrir un compte</title>
                <meta name="description" content="
              Cette banque est-elle réellement gratuite ? Avis détaillé de la banque HelloBank avec notations, présentation des services et tous les frais bancaires." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/banque/`} style={{color: 'black'}}>Avis Banques</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Avis HelloBank</Breadcrumb.Item>
      	 </Breadcrumb>

	
	<Row>
		<Col sm={9}>  <h1 id='avish1' style={{color: 'rgb(41, 49, 61)'}}>Avis HelloBank</h1>
          <h2 id='avish2' style={{marginLeft: 0, marginBottom: 25, color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Banque en ligne</h2>
	
 <a href={go} target='_blank' rel="noreferrer">
                <Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', borderColor: 'rgb(240 0 87)', color: 'white'}}>Voir l'offre <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
                </a>

		  
		  </Col>
	
		<Col sm={3} id='logoAvis'>
		<a href={go} target='_blank' rel="noreferrer">
		<StaticImage
		  	src='../../images/hello-bank-logo.png'
			width={250}
			alt='logo hello bank'
			placeholder='tracedSVG'
			/>
		</a>
		</Col>
	</Row>

		 
<hr></hr>
<Row>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Compte bancaire</p></Col>
			<Col> <ReactStars {...star1} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Carte bancaire</p></Col>
			<Col><ReactStars {...star2} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Tarifs</p></Col>
			<Col><ReactStars {...star3} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Application</p></Col>
			<Col><ReactStars {...star4} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Comptabilité</p></Col>
			<Col><ReactStars {...star5} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Gestion</p></Col>
			<Col><ReactStars {...star6} /></Col>
		</Row>
	</Col>
</Row>
<hr></hr>
<Post>



<h2>Offre HelloBank</h2>
<p>L’offre de bienvenue est 3 mois offerts pour l’ouverture d’un compte individuel.</p>
<p>Cette offre est accessible à toute personne physique majeure capable, résidant en France, agissant à des fins non professionnelles et ayant souscrit la convention de compte de dépôt individuel Hello bank.</p>
<p>Dans la réalité, il y’a aussi une condition de revenu si vous souhaitez accéder à l’offre avec la carte bancaire Hello Prime.</p>

<iframe id='videoYT'src="https://www.youtube.com/embed/CnD4Bj4Ce1s" title="avis HelloBank" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


<hr></hr>


<h2>Cartes bancaires HelloBank</h2>
<p>HelloBank sépare bien ses offres de compte courant individuel et compte joint en proposant des cartes bancaires bien distincts.</p>
<div style={{marginLeft: -5}}>
<DataTable row={[17,18,19,20]} what='cartesbancaires' id='postTable'/>
<DataTable row={[17,18,19,20]} what='cartesbancaires' id='mobileTable'/>

</div>

<h3>Carte Visa Hello One</h3>
<ul>
<li>Carte à autorisation systématique</li>
<li>Aucune condition de revenu</li>
<li>Gratuit partout dans le monde</li>
<li>Gratuit dans tous les distributeurs BNP Paribas et de ses filiales</li>
<li>Blocage / déblocage de carte</li>
<li>Visualisation des opérations de carte en temps réel</li>
</ul>


<h3>Carte Visa Hello Prime</h3>
<p>Les services gratuits de la carte Visa Hello Prime sont :</p>
<ul>
<li>Carte à débit immédiat ou différé (au choix)</li>
<li>Aucune condition de revenu</li>
<li>Gratuit partout dans le monde</li>
<li>Blocage / déblocage de carte</li>
<li>Visualisation des opérations de carte en temps réel</li>
<li>Assurance et assistance renforcée</li>
<li>Virements SEPA instantanés gratuits</li>
<li>Facilité de caisse : la facilité de caisse vous permet d’être débiteur durant une période de 15 jours sur 30 jours.</li>

</ul>


<div style={{maxWidth: 800, margin: 'auto'}}>
			<StaticImage
		  	src='../../images/hello-bank-site.png'
			width={800}
			alt='site hellobank'
			placeholder='tracedSVG'
			id='pickky'
			/>
			</div>




			<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
			<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>HelloBank <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

	<h2>Avis HelloBank – une application très complète</h2>
<h3>Agrégateur de compte</h3>
<p>
HelloBank propose une toute nouvelle application avec une fonction agrégateur de comptes bancaires extérieures. Cette fonction facilite la gestion de votre budget avec un regards sur tous vos comptes en un coup d’oeil.
</p>
<h3>
Temps réel</h3>
<p>
Visualisation des opérations en temps réel pour les cartes à débit immédiat</p>

<h3>
Interface complète pour vos paiements</h3>
<ul>
	<li>Payer par virement et envoyer votre RIB directement de votre app</li>
	<li>Cagnotte avec Lyf Pay</li>
	<li>Paylib pour le paiement entre amis</li>
	<li>Compatible avec Apple pay</li>
	<li>Payer aussi avec votre montre connectée avec l’application Hello Watch</li>
</ul>



<h3>Gestion de vos alertes et notifications</h3>

<h3>Catégorisation de vos dépenses et recettes</h3>
<p>
L’application vous permettra de surveiller l’évolution de vos dépenses</p>
<hr></hr>

<h2>Frais Bancaire Avis Boursorama Banque</h2>
<h3>Virements sortants</h3>
<DataTable row={[17,18,19,20]} what='virementSortants' id='postTable'/>
<DataTable row={[17,18,19,20]} what='virementSortants' id='mobileTable'/>

<h3>Virements entrants</h3>
<DataTable row={[17,18,19,20]} what='virementEntrants' id='postTable'/>
<DataTable row={[17,18,19,20]} what='virementEntrants' id='mobileTable'/>

<h3>Découverts</h3>
<DataTable row={[17,18,19,20]} what='decouverts' id='postTable'/>
<DataTable row={[17,18,19,20]} what='decouverts' id='mobileTable'/>

<h3>Irrégularités et incidents</h3>
<DataTable row={[17,18,19,20]} what='irregularites' id='postTable'/>
<DataTable row={[17,18,19,20]} what='irregularites' id='mobileTable'/>

<h2>HelloBank Pour Les Voyageurs</h2>
<h3>Retraits et paiements à l’étranger</h3>

<DataTable row={[17,18,19,20]} what='retraits' id='postTable'/>
<DataTable row={[17,18,19,20]} what='retraits' id='mobileTable'/>

<h3>Assurances</h3>
<DataTable row={[17,18,19,20]} what='assurances' id='postTable'/>
<DataTable row={[17,18,19,20]} what='assurances' id='mobileTable'/>



<h2>Changer de banque facilement avec Hello Start</h2>
<p>
Le plus pénible quand on change de banque est de contacter tous les prestataires du type électricité et forfait téléphone pour leur annoncer du changement de vos coordonnées bancaires.
</p><p>
HelloBank propose le service gratuit Hello Start qui vous permettra d’indiquer votre changement de domiciliation bancaire à tout le monde et en quelques cliques. HelloBank s’occupe de tout et vous pourrez suivre l’avancée des modifications de chaque prestataire directement de votre tableau de bord.
</p>

<h2>
Ouverture d’un compte banque en ligne chez HelloBank</h2>
<p>
L’ouverture de compte est rapide et se fait en 4 étapes :</p>

<p>
Étape 1 : remplissez un simple formulaire en ligne</p>
<p>
Étape 2 : Signez électroniquement votre contrat par SMS</p>
<p>
Étape 3 : Envoyez vos pièces justificatives en prenant une simple photo de votre smartphone</p>

<ul>
	<li>2 justificatifs d’identité</li>
	<li>1 justificatif de domicile</li>
	<li>1 RIB à vos nom et prénom</li>
	<li>Votre dernier justificatif de revenus</li>
	<li>Votre signature manuscrite</li>
</ul>

<p>
Étape 4 : Effectuez votre premier versement pour activer votre compte et recevoir votre carte visa chez vous. Le premier versement doit être entre 10 et 300€ en provenance d’un autre compte ouvert en votre nom.
</p><p>
L’ouverture de compte est encore plus simple si vous êtes déjà client BNP Paribas. Dans ce cas vous n’aurez pas besoin d’envoyer de pièces justificatives ou d’effectuer une premier versement.
</p>
<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Ouvrir un compte <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

		</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default avis
